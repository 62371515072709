/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTheme } from '@/components/theme-provider'
import { fetchReadSignedApi } from '@/hooks/api/apiConfig'
import {
  IconArrowLeft,
  IconFileTypeDoc,
  IconFileTypeDocx,
  IconFileTypePdf,
  IconLoader,
  IconPlayerPauseFilled,
  IconPlayerPlayFilled,
} from '@tabler/icons-react'
import { useWavesurfer } from '@wavesurfer/react'
import { get } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'

const EVENTS_MAPPING: any = {
  'ORDER::CASE_CREATED': {
    title: 'RED OS',
    description: (payload: any) => (
      <div className='flex flex-col items-start text-start'>
        <p className='text-sm font-medium text-gray-800 dark:text-white'>
          {`New case created with Case ID: ${payload?.caseId}`}
        </p>
      </div>
    ),
  },
  'ORDER::FLEET_ASSIGNED': {
    title: 'RED OS',
    description: (payload: any) => (
      <div className='flex flex-col items-start text-start'>
        <p className='text-sm font-medium text-gray-800 dark:text-white'>
          {`New case asigned to ${payload?.payload?.assignmentDetails?.fleetDetails?.ambNumberPlate}`}
        </p>
      </div>
    ),
  },
  'ORDER::PATIENT_PICKED_UP': {
    title: 'RED OS',
    description: (payload: any) => (
      <div className='flex flex-col items-start text-start'>
        <p className='text-sm font-medium text-gray-800 dark:text-white'>
          {'Patient picked up'}
        </p>
        <a
          href={get(payload, 'payload.trackingLink', '-')}
          className='font-medium underline underline-offset-4 hover:text-primary'
        >
          Tracking link
        </a>
      </div>
    ),
  },
  'ORDER::PATIENT_DROPPED': {
    title: 'RED OS',
    description: (payload: any) => (
      <div className='flex flex-col items-start text-start'>
        <p className='text-sm font-medium text-gray-800 dark:text-white'>
          {'Patient dropped off'}
        </p>
      </div>
    ),
  },
  'ORDER::CASE_FULFILLED': {
    title: 'RED OS',
    description: (payload: any) => (
      <div className='flex flex-col items-start text-start'>
        <p className='text-sm font-medium text-gray-800 dark:text-white'>
          {'Case is completed'}
        </p>
      </div>
    ),
  },
  'ORDER::FLEET_ASSIGNMENT_CANCELLED': {
    title: 'RED OS',
    description: (payload: any) => (
      <div className='flex flex-col items-start text-start'>
        <p className='text-sm font-medium text-gray-800 dark:text-white'>
          {'Assignment is cancelled'}
        </p>
        <p className='text-sm font-medium text-gray-800 dark:text-white'>
          {'Cancellation Reason: ' +
            get(payload, 'payload.cancellationReason', '-')}
        </p>
      </div>
    ),
  },
  'ORDER::CASE_CANCELLED': {
    title: 'RED OS',
    description: (payload: any) => (
      <div className='flex flex-col items-start text-start'>
        <p className='text-sm font-medium text-gray-800 dark:text-white'>
          {'Case is cancelled'}
        </p>
        <p className='text-sm font-medium text-gray-800 dark:text-white'>
          {'Cancellation Reason: ' +
            get(payload, 'payload.cancellationReason', '-')}
        </p>
      </div>
    ),
  },
  'ORDER::CHAT_NEW_IMAGE': {
    description: (payload: any) => (
      <div className='flex flex-col items-start text-start'>
        <p className='text-sm font-medium text-gray-800 dark:text-white'>
          {`New image sent from: ${(payload?.payload?.senderPlatform ?? '-').replace(/_/g, ' ')}`}
        </p>
        <ImageDisplay
          assetURL={payload?.payload?.assetUrl}
          channel_id={payload?.caseId}
        />
      </div>
    ),
  },
  'ORDER::CHAT_NEW_TEXT_MESSAGE': {
    description: (payload: any) => (
      <div className='flex flex-col items-start text-start'>
        <p className='text-sm font-medium text-gray-800 dark:text-white'>
          {`New text message sent from: ${(payload?.payload?.senderPlatform ?? '-').replace(/_/g, ' ')}`}
        </p>
        <p className='text-sm font-medium text-gray-800 dark:text-white'>
          {get(payload, 'payload.content', '-')}
        </p>
      </div>
    ),
  },
  'ORDER::CHAT_NEW_DOCUMENT': {
    description: (payload: any) => (
      <div className='flex flex-col items-start text-start'>
        <p className='text-sm font-medium text-gray-800 dark:text-white'>
          {`New document uploaded from: ${(payload?.payload?.senderPlatform ?? '-').replace(/_/g, ' ')}`}
        </p>
        <DocumentDisplay
          assetURL={payload?.payload?.assetUrl}
          format={payload?.payload?.format}
          channel_id={payload?.caseId}
        />
      </div>
    ),
  },
  'ORDER::CHAT_NEW_AUDIO_CHAT': {
    description: (payload: any) => (
      <div className='flex flex-col items-start text-start'>
        <p className='text-sm font-medium text-gray-800 dark:text-white'>
          {`New audio message sent from: ${(payload?.payload?.senderPlatform ?? '-').replace(/_/g, ' ')}`}
        </p>
        <AudioPlayer
          assetURL={payload?.payload?.assetUrl}
          channel_id={payload?.caseId}
        />
      </div>
    ),
  },
  'ORDER::CHAT_NEW_VIDEO': {
    description: (payload: any) => (
      <div className='flex flex-col items-start text-start'>
        <p className='text-sm font-medium text-gray-800 dark:text-white'>
          {`New video message sent from: ${(payload?.payload?.senderPlatform ?? '-').replace(/_/g, ' ')}`}
        </p>
        <VideoDisplay
          assetURL={payload?.payload?.assetUrl}
          channel_id={payload?.caseId}
        />
      </div>
    ),
  },
  'VIDEO::CALL_JOINED': {
    description: (payload: any) => (
      <div className='flex flex-col items-start text-start'>
        <p className='text-sm font-medium text-gray-800 dark:text-white'>
          {`Video call initiated from: ${(payload?.payload?.platform ?? '-').replace(/_/g, ' ').replace(/-/g, ' ').toUpperCase()}`}
        </p>
      </div>
    ),
  },
  'VIDEO::USER_JOINED': {
    description: (payload: any) => (
      <div className='flex flex-col items-start text-start'>
        <p className='text-sm font-medium text-gray-800 dark:text-white'>
          {`Video call initiated from: ${(payload?.payload?.platform ?? '-').replace(/_/g, ' ').replace(/-/g, ' ').toUpperCase()}`}
        </p>
      </div>
    ),
  },
  'ORDER::FSR_COMPLETE': {
    title: 'RED OS',
    description: (payload: any) => (
      <div className='flex flex-col items-start text-start'>
        <p className='text-sm font-medium font-medium text-gray-800 dark:text-white'>
          {get(payload, 'eventMessage', '-')}
        </p>
        <p className='text-sm font-medium text-gray-800 dark:text-white'>
          {get(payload, 'payload.email') +
            ' - ' +
            get(payload, 'payload.role', '-').replace(/_/g, ' ')}
        </p>
      </div>
    ),
  },
  'ORDER::PATIENT_REACHING': {
    title: 'RED OS',
    description: (payload: any) => (
      <div className='flex flex-col items-start text-start'>
        <p className='text-sm font-medium text-gray-800 dark:text-white'>
          {`Patient reaching in 5 minutes`}
        </p>
      </div>
    ),
  },
}

function DocumentDisplay({ assetURL, channel_id, format }) {
  const [urlLoaded, setUrlLoaded] = useState<boolean>(false)
  const [srcURL, setSrcURL] = useState<string>('')
  const [fileName, setFileName] = useState('')

  useEffect(() => {
    let tempFileName = ''
    if (assetURL) {
      setUrlLoaded(true)
      const temp = assetURL?.split('/')
      tempFileName = temp[temp?.length - 1]
      setFileName(tempFileName)
    }
    if (!srcURL && assetURL) {
      fetchReadSignedApi(channel_id, tempFileName).then((data: any) => {
        setSrcURL(data?.data?.data?.signedUrl)
      })
    }
  }, [assetURL])

  return (
    <div className='relative mb-1 mt-3 w-fit max-w-full overflow-hidden rounded-xl bg-slate-200 bg-opacity-50 dark:bg-slate-400 dark:bg-opacity-30 sm:max-w-[18rem]'>
      {urlLoaded && (
        <a href={srcURL} className='' target='_blank'>
          <iframe src={srcURL}></iframe>
          <div className='absolute top-0 h-4/6 w-full'></div>
        </a>
      )}
      {!urlLoaded && <IconLoader className='mx-auto animate-spin' size={32} />}
      <div className=' bottom-0 left-0 flex h-14 w-full items-center justify-between gap-2 bg-slate-200 px-5 py-3 dark:bg-slate-700'>
        {format == 'pdf' && <IconFileTypePdf size={30} />}
        {format == 'doc' && <IconFileTypeDoc size={30} />}
        {format == 'docx' && <IconFileTypeDocx size={30} />}
        <div className='whitespace-wrap !line-clamp-1 w-full text-ellipsis break-words text-xs sm:text-sm'>
          {fileName}
        </div>
      </div>
    </div>
  )
}

function ImageDisplay({ assetURL, channel_id }) {
  // const fileName = assetURL.split('?')[0].replace(/^.*[/]/, '')
  const [imageSection, setImageSection] = useState('')
  const [loading, setLoading] = useState(true)
  const [srcURL, setSrcURL] = useState<string>('')
  let fileName = ''

  useEffect(() => {
    if (assetURL) setLoading(true)

    if (!srcURL && assetURL) {
      const temp = assetURL?.split('/')
      fileName = temp[temp?.length - 1]

      fetchReadSignedApi(channel_id, fileName).then((data: any) => {
        setSrcURL(data?.data?.data?.signedUrl)
      })
    }
  }, [assetURL])

  return (
    <div className='relative my-1 overflow-hidden rounded-xl sm:max-w-full'>
      {loading && <IconLoader className='mx-auto animate-spin' size={32} />}
      <img
        onClick={() => {
          setImageSection(srcURL)
        }}
        alt={fileName}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.alt = 'Unable to load the picture'
          setLoading(false)
        }}
        onLoad={() => {
          setLoading(false)
        }}
        className={
          'max-h-[400px] max-w-[14rem] cursor-pointer text-xs text-slate-400 sm:max-w-[18rem]' +
          (loading ? 'hidden' : 'block')
        }
        src={srcURL}
      />
      {imageSection && (
        <div className='fixed left-0 top-0 z-[100000] box-border grid h-[100vh] w-[100vw] place-content-center bg-black bg-opacity-40 backdrop-blur-sm'>
          <button
            onClick={() => {
              setImageSection('')
              setLoading(true)
            }}
            className='absolute left-0 top-0 p-5'
          >
            <IconArrowLeft />
          </button>
          <div
            onClick={() => {
              setImageSection('')
            }}
            className='absolute left-0 top-0 -z-10 h-full w-full '
          ></div>
          {loading && (
            <IconLoader
              color='white'
              className='mx-auto animate-spin'
              size={52}
            />
          )}
          <img
            onLoad={() => {
              setLoading(false)
            }}
            draggable={true}
            className={
              'max-h-[95vh] max-w-[95vw] text-xs text-slate-400 transition-[transform]'
            }
            src={imageSection}
          ></img>
        </div>
      )}
    </div>
  )
}

function AudioPlayer({ assetURL, channel_id }) {
  const waveform = useRef(null)
  const { theme } = useTheme()
  const [srcURL, setSrcURL] = useState<string>('')
  const [loading, setLoading] = useState(true)
  const [currentTimePlayed, setCurrentTimePlayed] = useState<string>('00:00')

  useEffect(() => {
    if (!srcURL && assetURL) {
      const temp = assetURL?.split('/')
      const fileName = temp[temp?.length - 1]

      fetchReadSignedApi(channel_id, fileName).then((data: any) => {
        setSrcURL(data?.data?.data?.signedUrl)
      })
    }
  }, [assetURL])

  const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
    container: waveform,
    url: srcURL,
    waveColor: theme == 'light' ? '#dddddd' : '#4b6b8e',
    progressColor: theme == 'light' ? '#9e9e9e' : '#354c5b',
    height: 100,
    cursorColor: theme == 'light' ? '#9e9e9e' : '#ddd5e9',
    cursorWidth: 2,
    // mediaControls: true,
    dragToSeek: true,
    minPxPerSec: 0.1,
    // barAlign:  "bottom",
    barHeight: 0.3,
    autoCenter: true,
    autoScroll: true,
    // Set a bar width
    barWidth: 1,
    // Optionally, specify the spacing between bars
    barGap: 4,
    // And the bar radius
    barRadius: 20,
  })

  const convertTime = (currentTimeMillis: number) => {
    const hrs = ~~(currentTimeMillis / 3600)
    const mins = ~~((currentTimeMillis % 3600) / 60)
    const secs = ~~currentTimeMillis % 60

    let ret = ''

    if (hrs > 0) {
      ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
    }

    ret += '' + mins + ':' + (secs < 10 ? '0' : '')
    ret += '' + secs

    return ret
  }

  useEffect(() => {
    setCurrentTimePlayed(convertTime(currentTime))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime])

  useEffect(() => {
    wavesurfer &&
      wavesurfer.on('ready', function (duration) {
        setCurrentTimePlayed(() => convertTime(duration))
        setLoading(false)
      })
  }, [wavesurfer])

  function playPauseAudio() {
    // console.log(wavesurfer)
    wavesurfer && wavesurfer.playPause()
  }
  return (
    <>
      <div className='flex h-4 w-[14rem] items-center gap-4 overflow-hidden rounded-full p-5 sm:w-[18rem]'>
        {loading && <IconLoader className='mx-auto animate-spin' size={32} />}
        <div onClick={playPauseAudio} className='cursor-pointer'>
          {isPlaying ? (
            <IconPlayerPauseFilled className='text-slate-400 dark:text-[#ddd5e9]' />
          ) : (
            <IconPlayerPlayFilled className='text-slate-400 dark:text-[#ddd5e9]' />
          )}
        </div>
        <div className='w-[9rem] sm:w-[13rem]' ref={waveform}></div>
      </div>
      <div className={`pl-14`}>
        {currentTimePlayed}
        <div></div>
      </div>
    </>
  )
}

function VideoDisplay({ assetURL, channel_id }) {
  const [loading, setLoading] = useState(true)
  const [srcURL, setSrcURL] = useState<string>('')
  // const [fileName, setFileName] = useState('');

  useEffect(() => {
    let tempFileName = ''
    if (assetURL) setLoading(true)

    if (!srcURL && assetURL) {
      const temp = assetURL?.split('/')
      tempFileName = temp[temp?.length - 1]
      // setFileName(tempFileName)

      fetchReadSignedApi(channel_id, tempFileName).then((data: any) => {
        setSrcURL(data?.data?.data?.signedUrl)
        setLoading(false)
      })
    }
  }, [assetURL])

  // const [videoSection, setVideoSection] = useState('')
  return (
    <div className='relative my-1 overflow-hidden rounded-xl'>
      {loading && <IconLoader className='mx-auto animate-spin' size={32} />}
      {/* <div
        className=' bottom-0 flex h-14 w-full items-center justify-between gap-2 bg-slate-200 px-5 py-3 dark:bg-slate-700'
        onClick={() => setVideoSection(srcURL)}
      >
        <IconVideo size={30} />
        <div className='whitespace-wrap !line-clamp-1 w-full text-ellipsis break-words text-xs sm:text-sm'>
          {assetURL}
        </div>
      </div> */}
      {srcURL && (
        // <div className='fixed left-0 top-0 z-[100000] box-border grid h-[100vh] w-[100vw] place-content-center bg-black bg-opacity-40 backdrop-blur-sm'>
        //   <button
        //     onClick={() => {
        //       setVideoSection('')
        //     }}
        //     className='absolute left-0 top-0 p-5'
        //   >
        //     <IconArrowLeft />
        //   </button>
        //   <div
        //     onClick={() => {
        //       setVideoSection('')
        //     }}
        //     className='absolute left-0 top-0 -z-10 h-full w-full '
        //   ></div>
        <ReactPlayer
          url={srcURL}
          playing={false}
          controls
          loop={true}
          onBuffer={() => {
            console.log('bufferstart')
          }}
          className={
            'h-fit max-w-[14rem] cursor-pointer text-xs text-slate-400 sm:max-w-[18rem]' +
            (loading ? 'hidden' : 'block')
          }
          onBufferEnd={() => {
            console.log('buffer end')
          }}
        />
        // </div>
      )}
    </div>
  )
}

export default EVENTS_MAPPING
